import { ButtonContent } from "../../global/boton/ButtonContent";

function HeroSection({ bgImg, bgImg1, bgImg2, bgImg3, title, texts }) {

  const BgImages = [
    bgImg,
    bgImg1,
    bgImg2,
    bgImg3,
  ]

  return (
    <section className={'svgBoxRectanguloBottonRigth'}>

      <div className="pic-wrapper svgHerosection2  w-full h-[104vh]" >
        {
          BgImages.map((item, index) => {
            return (
              <figure
                key={index}
                className={`pic-${index + 1}`}
                style={{ background: `url("${item}")` }}
              ></figure>
            )
          })
        }
        <div className="z-10 relative w-4/5 mx-auto h-full flex flex-col md:flex-row items-center justify-end md:items-end pb-5 md:pb-5">
          <div className="md:w-1/2 w-[95%]">
            <div className="w-full flex flex-col justify-center md:justify-start items-center">
              <h1 className="text-white text-center md:text-start text-[25px] md:text-[50px]">
                {title}
              </h1>
              <p className="text-white text-center md:text-start ">
                {texts}
              </p>
            </div>

            <div className="w-full flex justify-center md:justify-start py-5">
              <ButtonContent btnStyle={'three'} />
            </div>
          </div>

          <div className="w-1/2 flex justify-center items-center md:mb-32 mb-0">
            <img src="https://firebasestorage.googleapis.com/v0/b/imagenes-fc412.appspot.com/o/Años%20de%20experiencia%2F18%20Years.png?alt=media&token=9394e2a3-8c3a-4032-ada4-b04155a6514d" alt="years"
              className="w-[300px]" />
          </div>

        </div>
      </div>


    </section>

  )
}

export default HeroSection;
